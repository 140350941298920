<template>
    <div class="body">
        <div class="container">
            <div class="header">
                <van-search v-model="keyword"  placeholder="请输入搜索关键词" @search="onSearch" />
                <div class="tabs-wrap">
                    
                
                    <div class="tabs clearfix">
                        <div class="tab-item" 
                          :class="actived==0?'cur': ''"
                          @click="changeType(0)">全部</div>
                        <div class="tab-item" 
                         :class="actived==item.id ?'cur': ''"
                          @click="changeType(item.id)" v-for="(item, index) in typelist" :key="index">
                        {{ item.name }}</div>
                    </div>
                </div>
                <div class="line full-width"></div>
            </div>
            <div class="section list with-footer">
                <!-- <div class="section-header">
                    <strong class="text-ellipsis">{{task.name}}</strong>
                </div> -->
                <div class="section-body">
                    <div class="section-body-item"
                        v-for="(item, i) in list" :key="i"
                        @click="toDetail(item.id)"
                        >
                        <img :src="item.cover" >
                        <div class="title text-ellipsis">
                            {{item.name}}
                        </div>
                        <div class="liulan flex">
                            <span class="" style="padding-right: 15px;">
                                <!-- <img src="../assets/images/dz-cur.png" alt=""> -->
                                <van-icon name="like" size="15" color="#9e9e9e" />
                                {{getzan(item.statistics)}}
                            </span>
                            <span class="">
                                <!-- <img src="../assets/images/sc-cur.png" alt=""> -->
                                <van-icon name="star" size="16" color="#9e9e9e" />
                                {{getshou(item.statistics)}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getCoursewareList, getCoursewareTypeList} from '@/api/task.js';
    import {formatSecond, checkLogin} from '@/utils/common.js';
    export default{
        activated() {
            checkLogin().then(()=> {
                this.getPageData();
            })
            this.hideTab();
        },
        data() {
            return{
                actived: 0,
                keyword: '',
                list: [],
                typelist: [],
            }
        },
        methods:{
            getPageData(){
                Promise.all([this.getLessons(), this.getTypeList()]);
            },
            getLessons(){
                var params = {
                    keywords: this.keyword,
                    typeid: this.actived,
                }
               getCoursewareList(params).then((ret)=>{
                   console.log(ret);
                   this.list = ret.data;
               }) 
            },
            getTypeList(){
                getCoursewareTypeList().then((ret)=>{
                    console.log(ret);
                    this.typelist = ret.data;
                }) 
            },
            changeType(type){
                this.actived = type;
                this.getLessons();
            },
            toDetail(id){
                this.$router.push(`/courseware-detail?id=${id}`);
            },
            format(s){
             return formatSecond(s);
            },
            getPercent(lesson){
                var div = Math.floor(Number(lesson.speed_num)*10000/Number(lesson.total_num));
                return div/100;
            },
            onSearch(){
                console.log(this.keyword);
                this.getLessons();
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.remove('tab-hide');
            },
            getzan(list){
                return list.filter((item)=>{return item.type ==0;}).length;
            },
            getshou(list){
                return list.filter((item)=>{return item.type ==1;}).length;
            },
        }
    }
</script>

<style scoped>
    @import url('../../assets/css/common.css');
    .container{
        bottom: 50px;
    }
    .line{
        height: 1px;
        background-color: #ccc;
        margin: 10px 0 10px;
    }
    .van-search{
        padding: 0;
    }
    .tabs-wrap{
        overflow: auto;
    }
    .tabs{
        margin: 10px 0 6px;
        font-size: 16px;
        font-weight: 400;
        width: max-content;
        overflow-y: hidden;
        overflow-x: auto;
    }
    .tab-item{
        margin-right: 10px;
        float: left;
    }
    .tab-item.cur{
        color: #1989fa;
        font-weight: bold;
    }
</style>